<template>
    <div id="secure">
        <h1>PAW</h1>
        <table v-if="paw" id="table">
            <!--
            <tr>
                <th>PAW-ID</th>
                <td>{{ paw.id }}</td>
            </tr>
            -->
            <tr>
                <th>Bezeichnung</th>
                <td>{{ paw.description }}</td>
            </tr>
            <tr>
                <th>Name</th>
                <td>{{ paw.forename }} {{ paw.name }}</td>
            </tr>
            <tr>
                <th>Geburtstag</th>
                <td>{{ dateFormat(paw.birthDate, "myDate") }}</td>
            </tr>
            <tr>
                <th>Geschlecht</th>
                <td>{{ gender }}</td>
            </tr>
            <tr>
                <th>Gewicht</th>
                <td v-if="paw.weight && paw.weight.toString().length > 0">{{ paw.weight }} kg</td>
                <td v-else>nicht angegeben</td>
            </tr>
            <tr>
                <th>Größe</th>
                <td v-if="paw.height && paw.height.toString().length > 0">{{ paw.height }} cm</td>
                <td v-else>nicht angegeben</td>
            </tr>
            <tr>
                <th>Schuhgröße</th>
                <td v-if="paw.shoeSize && paw.shoeSize.toString().length > 0">{{ paw.shoeSize }}</td>
                <td v-else>nicht angegeben</td>
            </tr>
            <tr>
                <th>Anmerkungen</th>
                <td>{{ commentHTML }}</td>
            </tr>
            <tr>
                <th>Lieferadresse</th>
                <td>
                    {{ paw.deliveryAddress.street }}
                    <br>
                    {{ paw.deliveryAddress.postCode }}
                    {{ paw.deliveryAddress.city }}
                    <br>
                    {{ paw.deliveryAddress.country }}
                    <br>
                </td>
            </tr>
            <tr>
                <th>Partner</th>
                <td>
                    {{ pawPartner }}
                </td>
            </tr>
            <tr>
                <th :class="statusClass">Status</th>
                <td :class="statusClass">
                    {{ status }}
                    <br v-show="statusText">
                    {{ statusText? statusText : "" }}
                    <br v-show="statusText">
                    <div style="width: 100%; text-align: left">
                    <pre v-if="statusText">
                        {{paw.reason}}
                    </pre>
                    </div>
                </td>
            </tr><!--
            <tr>
                <th>Stand</th>
                <td>
                    <pre>{{ paw.lastChange.toLocaleDateString() }} {{ paw.letzte_aenderung.toLocaleTimeString() }}</pre>
                </td>
            </tr>-->
            <tr>
                <th>
                    Bilder links
                    <br>
                    <md-button class="md-raised" v-on:click="addImages(0)" v-if="leftExists && picsAddable(paw.status)">
                        ändern
                    </md-button>
                </th>
                <td>
                    <table v-if="leftExists" class="foot_images">
                        <tr>
                            <th>mit Fuß</th>
                            <th>ohne Fuß</th>
                        </tr>
                        <tr>
                            <td>
                                <img :src="paw.leftWithFoot" alt="Bild links mit Fuß"
                                     v-on:click="imageDetails(paw.leftWithFoot)">
                            </td>
                            <td>
                                <img :src="paw.leftWithoutFoot" alt="Bild links ohne Fuß"
                                     v-on:click="imageDetails(paw.leftWithoutFoot)">
                            </td>
                        </tr>
                    </table>
                    <div v-else-if="!leftExists && picsAddable(paw.status)">
                        <md-button class="md-raised" v-on:click="addImages(0)">Bilder hinzufügen</md-button>
                    </div>
                    <div v-else>
                        keine Bilder eingereicht
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    Bilder rechts
                    <br>
                    <md-button class="md-raised" v-on:click="addImages(1)" v-if="rightExists && picsAddable(paw.status)">
                        ändern
                    </md-button>
                </th>
                <td>
                    <table v-if="rightExists" class="foot_images">
                        <tr>
                            <th>mit Fuß</th>
                            <th>ohne Fuß</th>
                        </tr>
                        <tr>
                            <td>
                                <img :src="paw.rightWithFoot" alt="Bild rechts mit Fuß"
                                     v-on:click="imageDetails(paw.rightWithFoot)">
                            </td>
                            <td>
                                <img :src="paw.rightWithoutFoot" alt="Bild rechts ohne Fuß"
                                     v-on:click="imageDetails(paw.rightWithoutFoot)">
                            </td>
                        </tr>
                    </table>
                    <div v-else-if="!rightExists && picsAddable(paw.status)">
                        <md-button class="md-raised" v-on:click="addImages(1)">Bilder hinzufügen</md-button>
                    </div>
                    <div v-else>
                        keine Bilder eingereicht
                    </div>
                </td>
            </tr>
            <tr v-if="leftExists || rightExists">
                <td colspan="2">
                    Für die Detailansicht auf das jeweilige Bild klicken.
                </td>
            </tr>
        </table>

        <br>

        <md-button v-show="paw && paw.status === PAWStatus.created" class="md-raised" v-on:click="addImages('-1')">
            alle Bilder hinzufügen
        </md-button>
        <md-button v-show="paw && paw.status === PAWStatus.imgsadded && leftExists && rightExists" class="md-raised" v-on:click="addImages('-1')">
            alle Bilder ändern
        </md-button>
        <!--<md-button class="md-raised md-accent" v-on:click="reqDeletePAW()">Löschen</md-button>-->
        <md-button v-if="paw && paw.status === PAWStatus.rejected" class="md-raised" v-on:click="offer()">
            Neu beauftragen
        </md-button>
        <md-button v-if="paw && paw.status === PAWStatus.imgsadded" class="md-raised" v-on:click="offer()">
            Beauftragen
        </md-button>
        <md-button v-if="paw && paw.status === PAWStatus.rework" class="md-raised" v-on:click="reoffer()">
            Erneut beauftragen
        </md-button>

        <md-dialog :md-active.sync="dialDelete">
            <md-dialog-title>
                Sicher löschen?
            </md-dialog-title>
            <md-dialog-content>
                Alle Daten zu diesem PAW werden auf dem Sever gelöscht.<br>
                ID: {{ deletePAWid }}
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised" @click="dialDelete = false">Doch nicht!</md-button>
                <md-button class="md-accent md-raised" @click="deletePAW">Löschen!</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog v-if="paw && imageDetail" :md-active.sync="dialImageDetail">
            <md-dialog-title>
                Detailansicht
            </md-dialog-title>
            <md-dialog-content>
                <img :src="imageDetail" alt="Bild rechts verarbeitet" class="imageDetail">
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised" @click="dialImageDetail = false; imageDetail = null">Schließen!</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import {dateFormat} from "../helpers/dates";
import {PAWStatus, toDescription, picsAddable} from "../helpers/PAWstates";

const emptyImg = "data:image/jpeg;base64,";

export default {
    name: 'User_PAW_detail',
    props: {},
    data: () => ({
        dialDelete: false,
        dialImageDetail: false,
        deletePAWid: null,
        imageDetail: null,
        paw: null,

        PAWStatus,
        dateFormat,
    }),
    computed: {
        status: function (){
            if(!this.paw)
                return ""
            return toDescription(this.paw.status)
        },
        statusText: function (){
            if(!this.paw)
                return null
            if(this.paw.status === PAWStatus.rework)
                return "Der Partner hat Deinen PAW abgelehnt, weil es Mängel gibt. " +
                    "Beseitige die Mängel und beauftrage den Partner erneut. " +
                    "Der PAW-Partner schreibt folgende Anmerkung:"
            if(this.paw.status === PAWStatus.rejected)
                return "Der Partner hat Deinen PAW abgelehnt. " +
                    "Dazu schreibt er folgende Anmerkung:"
            return null
        },
        statusClass: function () {
            if(!this.paw)
                return ""
            if(this.paw.status === PAWStatus.rejected || this.paw.status === PAWStatus.rework)
                return "statusAlarm"
            return ""
        },
        commentHTML: function () {
            let com = this.paw.comment.replace(/\r/g, "").replace(/\n/g, "<br>")
            if (com.replace(/ /g, "") === "") {
                return "keine Anmerkungen angegeben"
            } else {
                return com
            }
        },
        pawPartner: function () {
            return this.paw.pawPartner ? this.paw.pawPartner : "aktuell nicht beauftragt"
        },
        leftExists: function () {
            return this.paw.leftWithFoot && this.paw.leftWithoutFoot && this.paw.leftWithFoot.length > 0 && this.paw.leftWithoutFoot.length > 0
        },
        rightExists: function () {
            return this.paw.rightWithFoot && this.paw.rightWithoutFoot && this.paw.rightWithFoot.length > 0 && this.paw.rightWithoutFoot.length > 0
        },
        gender: function () {
            if (this.paw === null) {
                return ""
            } else {
                switch (this.paw.gender) {
                    case 0:
                        return "männlich"
                    case 1:
                        return "weiblich"
                    case 2:
                        return "divers"
                    default:
                        return ""
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch("loadPawDetails", this.$route.params.pawId).then(() => {
            this.paw = this.$store.state.paw_detail
            if (this.$store.state.paw_detail.pawPicSets.length > 0) {
                let left = null
                let right = null
                for (let elem of this.$store.state.paw_detail.pawPicSets) {
                    if (elem.feetType === 0) {
                        left = elem
                    } else if (elem.feetType === 1) {
                        right = elem
                    }
                }
                if(left){
                    this.paw.leftWithFoot = emptyImg + left.feet
                    this.paw.leftWithoutFoot = emptyImg + left.imprint
                }
                if(right){
                    this.paw.rightWithFoot = emptyImg + right.feet
                    this.paw.rightWithoutFoot = emptyImg + right.imprint
                }
            }
        }, this)
        this.deletePAWid = this.$route.params.pawId;
    },
    components: {},
    methods: {
        picsAddable,
        reqDeletePAW() {
            this.dialDelete = true;
        },
        deletePAW() {
            this.dialDelete = false;
            this.$store.dispatch("deletePAW", this.$route.params.pawId)
            this.$router.push("/user/dashboard/")
        },
        imageDetails(img) {
            this.imageDetail = img;
            this.dialImageDetail = true
        },
        addImages(leftright) {
            this.$router.push("/user/paw/addimages/" + this.$route.params.pawId + "/" + leftright);
        },
        offer() {
            this.$router.push("/user/paw/offer/" + this.$route.params.pawId)
        },
        reoffer() {
            let offerReq = {
                pawId: this.paw.id,
                companyName: this.paw.pawPartner
            }
            this.$store.dispatch("offerPaw", offerReq).then(() => {
                this.$store.dispatch("showSnackbar", {text: "Auftrag erfolgreich erteilt."})
            }, this).catch(() => {
                this.$store.dispatch("showSnackbar", {text: "Beauftragung fehlgeschlagen. Ist der Auftrag vielleicht schon vergeben?"})
            })
        }
    }
}
</script>

<style scoped>
td.statusAlarm {
    background-color: rgba(255,0,0,0.5);
    font-weight: bold;
}

#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

.imageDetail {
    height: 100%;
    max-height: 100%;
}

table#table {
    display: inline-block;
    border-collapse: collapse;
    max-width: 90%;
}

table#table > tr > th {
    font-size: large;
    padding: 10px;
}

table#table > tr > td {
    padding: 10px;
}

table#table > tr:nth-child(odd) {
    background: #EEE;
}

table#table > tr:nth-child(even) {
    background: #FFF;
}

table.foot_images {
    width: 100%;
}

table.foot_images > tr > td {
    width: 33%;
}
</style>
